import _Vue from 'vue'
import AppDialog from '@/components/AppDialog.vue'

const Dialog = {
  eventBus: new _Vue(),
  // every plugin for Vue.js needs install method
  // this method will run after Vue.use(<your-plugin-here>) is executed
  install: (Vue: typeof _Vue, options?: any) => {
    // We must create new Eventbus
    // which is just another Vue instance that will be listening for and emiting events from our main instance
    // this EventBus will be available as Modal.EventBus

    // making our modal component global
    Vue.component('app-dialog', AppDialog)

    // inside this object we can have modal title, text, styles... and also our callback confirm function
    Vue.prototype.$dialog = {
      showDoubleLoginError(dialogId: string, confirm: () => void) {
        // if we use this.$modal.showError(params) inside our original Vue instance
        Dialog.eventBus.$emit('showDoubleLoginError', confirm)
        Dialog.eventBus.$emit('dialogId', dialogId)
      },
      showError(dialogId: string, code: string, confirm?: () => void) {
        // if we use this.$modal.showError(params) inside our original Vue instance
        Dialog.eventBus.$emit('showError', code, confirm)
        Dialog.eventBus.$emit('dialogId', dialogId)
      },
      showConfirm(dialogId: string, code: string, confirm?: () => void, cancel?: () => void) {
        // if we use this.$modal.showConfirm(params) inside our original Vue instance
        Dialog.eventBus.$emit('showConfirm', code, confirm, cancel)
        Dialog.eventBus.$emit('dialogId', dialogId)
      },
      showBikeShareMessage(dialogId: string, code: string, confirm?: () => void) {
        // if we use this.$modal.showBikeShareMessage(params) inside our original Vue instance
        Dialog.eventBus.$emit('showBikeShareMessage', code, confirm)
        Dialog.eventBus.$emit('dialogId', dialogId)
      },
      showApiError(dialogId: string, code: string, confirm?: () => void) {
        // if we use this.$modal.showApiError(params) inside our original Vue instance
        Dialog.eventBus.$emit('showApiError', code, confirm)
        Dialog.eventBus.$emit('dialogId', dialogId)
      },
      hideDialog() {
        // if we use this.$modal.hide() inside our original Vue instance
        Dialog.eventBus.$emit('hide')
      },
      showInformation(dialogId: string, code: string, confirm?: () => void) {
        // if we use this.$modal.showInformation(params) inside our original Vue instance
        Dialog.eventBus.$emit('showInformation', code, confirm)
        Dialog.eventBus.$emit('dialogId', dialogId)
      },
    }
  },
}

interface IDialogPlugin {
  showError: (screenId: string, code: string, confirm?: () => void) => void
  showConfirm: (
    screenId: string,
    code: string,
    confirm: () => void,
    cancel?: () => void
  ) => void
  showBikeShareMessage: (screenId: string, code: string, confirm?: () => void) => void
  showApiError: (screenId: string, code: string, confirm?: () => void) => void
  showDoubleLoginError: (screenId: string, confirm: () => void) => void
  hideDialog: () => void
  showInformation: (screenId: string, code: string, confirm?: () => void) => void
}

declare module 'vue/types/vue' {
  interface Vue {
    $dialog: IDialogPlugin
  }
}

export default Dialog
