import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { firestoreAction } from 'vuexfire'
import { FirestoreService } from '@/services'
import { IRootState, ISystemState } from '../utilities'
import { IEnvironment, IProperties } from '@/models'

const state: ISystemState = {
  properties: {} as IProperties,
  environment: {} as IEnvironment,
}

const mutations: MutationTree<ISystemState> = {}

const actions: ActionTree<ISystemState, IRootState> = {
  getSystemProperties: firestoreAction(({ bindFirestoreRef }) => {
    return bindFirestoreRef('properties', FirestoreService.getSystemProperties(), {
      reset: true,
      wait: true
    })
  }),

  getSystemEnvironment: firestoreAction(({ bindFirestoreRef }) => {
    return bindFirestoreRef('environment', FirestoreService.getSystemEnvironment(), {
      reset: true,
      wait: true
    })
  }),
  getSystemRouteSearch: firestoreAction(({ bindFirestoreRef }) => {
    return bindFirestoreRef('routeSearch', FirestoreService.getSystemRouteSearch(), {
      reset: true,
      wait: true
    })
  }),
}

const getters: GetterTree<ISystemState, IRootState> = {
  properties: (state) => state.properties,
  environment: (state) => state.environment,
  link: (state) => state.properties.link,
  isMaintenance: (state) => state.properties.maintenance.isShow,
  isRestriction: (state) => state.properties.mobile.loginRestrictions,
}

const systemModule: Module<ISystemState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default systemModule
