var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(_vm.isLoadingFirestore)?[_c('splash-screen',{staticStyle:{"background":"#fafafa"}})]:[_c('app-header',{staticStyle:{"background":"#fafafa"}}),_c('v-content',[_c('v-container',{staticClass:"pt-0 pb-0",attrs:{"fill-height":"","align-content-start":"","fluid":"","id":"qr-body"}},[(
            _vm.$store.getters['system/properties'].notification.isShow &&
              _vm.$store.getters['layout/showNotificationMessage']
          )?_c('v-alert',{staticClass:"alert-container color-frame-header",attrs:{"dense":"","outlined":""}},[_c('div',{staticClass:"font-15",domProps:{"innerHTML":_vm._s(
              _vm.getContentByCurrentLanguage(
                _vm.$store.getters['system/properties'].notification.message
              )
            )}})]):_vm._e(),_c('v-fade-transition',[(_vm.systemProperties.maintenance.isShow)?_c('maintenance-screen'):(!_vm.isUserAuthenticated)?_c('login-process'):_c('router-view')],1)],1)],1),_c('app-footer'),_c('app-footer-menu'),_c('app-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}]})],_c('app-dialog')],2)}
var staticRenderFns = []

export { render, staticRenderFns }