import { Component, Prop, Vue } from 'vue-property-decorator'
import { isEqual } from 'date-fns'

@Component
export class DialogMixin extends Vue {
  @Prop() protected value!: boolean
  @Prop() protected title!: string
  @Prop() protected message!: string
  @Prop() protected dateLeft!: Date | number
  @Prop() protected dateRight!: Date | number

  protected get isExpirationDateEqual() {
    return isEqual(this.dateLeft, this.dateRight)
  }

  // 'no-scroll' class will still in effect
  /**
   * work around for a bug on ios chrome
   * prevent scrolling when dialog is shown
   */
  // @Watch('value')
  // onDialogStateChanged(isDialogShow: boolean) {
  //   if (isDialogShow) {
  //     document.getElementsByTagName('body')[0].className = 'no-scroll'
  //   } else {
  //     document.body.removeAttribute('class')
  //   }
  // }

  protected handleOkClick(): void {
    this.hideDialog()
    this.$emit('ok')
  }

  protected handleCancelClick(): void {
    this.hideDialog()
    this.$emit('cancel')
  }

  protected hideDialog(): void {
    this.$emit('input', false)
  }
}
