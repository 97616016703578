import { render, staticRenderFns } from "./AppDialog.vue?vue&type=template&id=8058dbbc&scoped=true&"
import script from "./AppDialog.vue?vue&type=script&lang=ts&"
export * from "./AppDialog.vue?vue&type=script&lang=ts&"
import style0 from "./AppDialog.vue?vue&type=style&index=0&id=8058dbbc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8058dbbc",
  null
  
)

export default component.exports