import firebase from 'firebase/app'
import 'firebase/auth'
// @ts-ignore
import firebaseConfig from '../../../firebaseConfig'
import { FirebaseApiName, FirebaseErrorCode } from '@/utilities'
import { StorageService } from './storage'
import { LanguageCode } from '../i18n/index'

firebase.initializeApp(firebaseConfig)

export class FirebaseService {
  private static setLanguage() {
    firebase.auth().languageCode = StorageService.getCurrentLanguage() || LanguageCode.Ja
  }

  private static async persistAuthStateBySession(): Promise<void> {
    try {
      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
    } catch (error) {
      // Nothing
    }
  }

  public static async loginWithGoogle(): Promise<void> {
    await this.persistAuthStateBySession()
    return firebase
      .auth()
      .signInWithRedirect(new firebase.auth.GoogleAuthProvider())
      .catch((error) => {
        return Promise.reject(
          createAuthErrorMessage(error, FirebaseApiName.signInWithRedirect)
        )
      })
  }

  public static async loginWithFacebook(): Promise<void> {
    await this.persistAuthStateBySession()
    return firebase
      .auth()
      .signInWithRedirect(new firebase.auth.FacebookAuthProvider())
      .catch((error) => {
        return Promise.reject(
          createAuthErrorMessage(error, FirebaseApiName.signInWithRedirect)
        )
      })
  }

  public static async login(email: string, password: string): Promise<firebase.auth.UserCredential> {
    await this.persistAuthStateBySession()
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((credential) => credential)
      .catch((error) => {
        return Promise.reject(
          createAuthErrorMessage(error, FirebaseApiName.signInWithEmailAndPassword)
        )
      })
  }

  public static signUp(
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential | string> {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((credential) => credential)
      .catch((error) => {
        return Promise.reject(
          createAuthErrorMessage(error, FirebaseApiName.createUserWithEmailAndPassword)
        )
      })
  }

  public static getRedirectLoginResult(): Promise<firebase.auth.UserCredential | null> {
    return firebase
      .auth()
      .getRedirectResult()
      .then((credential) => (credential.user ? credential : null))
      .catch((error) => {
        return Promise.reject(createAuthErrorMessage(error, FirebaseApiName.getRedirectResult))
      })
  }

  public static updateFirebaseUserProfile(displayName: string): Promise<void> {
    return firebase
      .auth()
      .currentUser!.updateProfile({ displayName })
      .catch((error) => {
        return Promise.reject(createAuthErrorMessage(error))
      })
  }

  public static sendPasswordResetEmail(email: string): Promise<void> {
    this.setLanguage()
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .catch((error) => {
        return Promise.reject(
          createAuthErrorMessage(error, FirebaseApiName.sendPasswordResetEmail)
        )
      })
  }

  public static updateFirebaseUserPassword(password: string): Promise<void> {
    return firebase
      .auth()
      .currentUser!.updatePassword(password)
      .catch((error) => {
        return Promise.reject(createAuthErrorMessage(error, FirebaseApiName.updatePassword))
      })
  }

  public static updateFirebaseUserEmail(email: string): Promise<void> {
    return firebase
      .auth()
      .currentUser!.updateEmail(email)
      .catch((error) => {
        return Promise.reject(createAuthErrorMessage(error, FirebaseApiName.updateEmail))
      })
  }

  public static sendEmailVerification() {
    this.setLanguage()
    return firebase
      .auth()
      .currentUser!.sendEmailVerification()
      .catch((error) => {
        return Promise.reject(
          createAuthErrorMessage(error, FirebaseApiName.sendEmailVerification)
        )
      })
  }

  public static logout(): Promise<void> {
    return firebase.auth().signOut()
  }
}

function createAuthErrorMessage(
  error: { code: string; message: string },
  apiName?: FirebaseApiName
) {
  switch (error.code) {
    case FirebaseErrorCode.AccountExistsWithDifferentCredential:
    case FirebaseErrorCode.AuthDomainConfigRequired:
    case FirebaseErrorCode.AuthUnauthorizedDomain:
    case FirebaseErrorCode.CredentialAlreadyInUse:
    case FirebaseErrorCode.OperationNotSupportedInThisEnvironment:
    case FirebaseErrorCode.Timeout:
    case FirebaseErrorCode.OperationNotAllowed:
    case FirebaseErrorCode.EmailAlreadyInUse:
    case FirebaseErrorCode.InvalidEmail:
    case FirebaseErrorCode.UserDisabled:
    case FirebaseErrorCode.UserNotFound:
    case FirebaseErrorCode.WrongPassword:
    case FirebaseErrorCode.TooManyRequests:
    case FirebaseErrorCode.NetworkRequestFailed:
    case FirebaseErrorCode.WeakPassword:
    case FirebaseErrorCode.MissingAndroidPkgName:
    case FirebaseErrorCode.MissingContinueUri:
    case FirebaseErrorCode.MissingIosBundleId:
    case FirebaseErrorCode.InvalidContinueUri:
    case FirebaseErrorCode.UnauthorizedContinueUri:
    case FirebaseErrorCode.RequiresRecentLogin:
      return `FIREBASE.${apiName}.${error.code}`
    default:
      return `FIREBASE.${FirebaseErrorCode.ERROR}`
  }
}
