import firebaseConfig from '@/../../firebaseConfig.js'
import i18n, { LanguageCode } from '@/i18n'
import customize from '@/../../customize.json'

import { filePath } from './file'
import { MAP_OPTIONS, MAP_COLOR, MAP_GEOCODER_STATUS, MAP_MARKER_TYPE } from './constant'
import {
  IPoint,
  IMapMarker,
  IMapPolyline,
  IMapMarkerIcon,
  IMapMarkerSymbol,
  IMapGeocoderRequest,
  IMapGeocoderResult,
  IMapGeocoderReverse,
} from '@/custommodels'

// --------------- INIT GOOGLE MAP API ---------------
// Your personal API key.
// Get it here: https://console.cloud.google.com/google/maps-apis
const API_KEY = firebaseConfig.apiKey
const CALLBACK_NAME = 'gmapsCallback'

let initialized = !!window['google']
let resolveInitPromise
let rejectInitPromise

// This promise handles the initialization
// status of the google maps script.
let initPromise = new Promise((resolve, reject) => {
  resolveInitPromise = resolve
  rejectInitPromise = reject
})

export function destroyMap() {
  document.querySelectorAll('script[src^="https://maps.googleapis.com"]').forEach((script) => {
    script.remove()
  })
  document.querySelectorAll('link[href^="https://fonts.googleapis.com"]').forEach((link) => {
    link.remove()
  })

  delete window['google']
  delete window[CALLBACK_NAME]
  initialized = false
  initPromise = new Promise((resolve, reject) => {
    resolveInitPromise = resolve
    rejectInitPromise = reject
  })
}

export function mapInit() {
  // If Google Maps already is initialized
  // the `initPromise` should get resolved
  // eventually.
  if (initialized) return initPromise

  initialized = true
  // The callback function is called by
  // the Google Maps script if it is
  // successfully loaded.
  window[CALLBACK_NAME] = () => resolveInitPromise(window['google'])

  // options additional
  const region = 'JP'
  const googleMapLocaleRecords: Record<string, string> = {
    [LanguageCode.Ja]: LanguageCode.Ja,
  }
  const language = googleMapLocaleRecords[i18n.locale] || i18n.locale
  const libraries = 'places'

  // We inject a new script tag into
  // the `<head>` of our HTML to load
  // the Google Maps script.
  const script: HTMLScriptElement = document.createElement('script')
  script.async = true
  script.defer = true
  script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=${libraries}&callback=${CALLBACK_NAME}&region=${region}&language=${language}`
  script.onerror = rejectInitPromise

  const head: HTMLElement | null = document.querySelector('head')
  head && head.appendChild(script)

  return initPromise
}

// --------------- MAPPER FOR GOOGLE MAP ---------------

// TODO move declare symbol some where
export const symbolStyles = {
  dashed: {
    path: 'M 0,-1 0,1',
    strokeOpacity: 1,
    strokeColor: 'black',
    strokeWeight: 1,
    scale: 2,
  },
  gps: {
    scale: 8,
    fillOpacity: 1,
    fillColor: '#1b71e8',
    strokeColor: '#ffffff',
    strokeWeight: 4,
    symbol: 'CIRCLE',
  },
  marker: {
    path:
      'M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z',
    scale: 1.5,
    anchor: {
      x: 12,
      y: 22,
    },
    fillOpacity: 1,
    fillColor: customize.default.cautionText,
    strokeColor: customize.default.cautionText,
  },
  place: {
    path:
      'M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4M12,6A6,6 0 0,0 6,12A6,6 0 0,0 12,18A6,6 0 0,0 18,12A6,6 0 0,0 12,6M12,8A4,4 0 0,1 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12A4,4 0 0,1 12,8Z',
    scale: 1.5,
    anchor: {
      x: 12,
      y: 12,
    },
    fillOpacity: 1,
    fillColor: 'rgb(128, 192, 221)',
    strokeColor: 'rgb(128, 192, 221)',
  },
}

// TODO move declare marker some where
export const markerStyles = {
  normal: {
    icon: {
      url: filePath.iconMapMarkerDefault,
      size: { width: 27, height: 43 },
      labelOrigin: { x: 13.5, y: 16 }, // x = size.width / 2
      type: MAP_MARKER_TYPE.IMAGE,
      // size and scaledSize must be the same
    },
    label: {
      color: MAP_COLOR.RED,
    },
  },
  selected: {
    icon: {
      url: filePath.iconMapMarkerSeleted,
      size: { width: 27, height: 43 },
      labelOrigin: { x: 13.5, y: 16 }, // x = size.width / 2
      type: MAP_MARKER_TYPE.IMAGE,
      // size and scaledSize must be the same
    },
    label: {
      color: MAP_COLOR.WHITE,
    },
  },
  currentLocation: {
    icon: {
      ...symbolStyles.gps,
      type: MAP_MARKER_TYPE.SYMBOL,
    },
  },
  markerPlace: {
    icon: {
      ...symbolStyles.marker,
      type: MAP_MARKER_TYPE.SYMBOL,
    },
  },
  placeDestination: {
    icon: {
      ...symbolStyles.place,
      type: MAP_MARKER_TYPE.SYMBOL,
    },
  },
}

// TODO move declare polyline some where
export const polylineStyles = {
  dashed: { icon: symbolStyles.dashed, offset: '0', repeat: '10px' },
}

export const initMapOptions = {
  zoom: MAP_OPTIONS.ZOOM,
  center: MAP_OPTIONS.CENTER,
  zoomControl: MAP_OPTIONS.ZOOM_CONTROL,
  scaleControl: MAP_OPTIONS.SCALE_CONTROL,
  rotateControl: MAP_OPTIONS.ROTATE_CONTROL,
  mapTypeControl: MAP_OPTIONS.TYPE_CONTROL,
  disableDefaultUi: MAP_OPTIONS.DEFAULT_UI,
  fullscreenControl: MAP_OPTIONS.FULL_CONTROL,
  streetViewControl: MAP_OPTIONS.VIEW_CONTROL,
  clickableIcons: MAP_OPTIONS.CLICKABLE_ICON,
}

function getIcon(google: any, icon: IMapMarkerIcon | IMapMarkerSymbol | any) {
  if (!icon) return undefined
  if (typeof icon === 'string') return icon
  if (typeof icon !== 'object') return undefined

  switch (icon.type) {
    case MAP_MARKER_TYPE.SYMBOL:
      return getSymbolIcon(google, icon)
    default:
      return getImageIcon(google, icon)
  }
}

export function getImageIcon(google: any, icon: any) {
  const { url, size, anchor, origin, labelOrigin } = icon as IMapMarkerIcon
  const rs: IMapMarkerIcon = { url }

  if (size && size.width && size.height) {
    rs.size = new google.Size(size.width, size.height) || null
    rs.scaledSize = new google.Size(size.width, size.height) || null
  }

  if (anchor && anchor.x && anchor.y) {
    rs.anchor = new google.Point(anchor.x, anchor.y)
  }

  if (origin && origin.x && origin.y) {
    rs.origin = new google.Point(origin.x, origin.y)
  }

  if (labelOrigin && labelOrigin.x && labelOrigin.y) {
    rs.labelOrigin = new google.Point(labelOrigin.x, labelOrigin.y)
  }

  return rs
}

export function getSymbolIcon(google: any, icon: any) {
  const { symbol, path } = icon as IMapMarkerSymbol
  const rs: IMapMarkerSymbol = { ...icon, path }

  if (symbol) {
    rs.path = google.SymbolPath[symbol]
    rs.symbol && delete rs.symbol
  }

  return rs
}

export function mapMarkersGmap(google: any, map: Element, markers: IMapMarker[]) {
  if (!markers.length || !google || !map) return []

  return markers.map((m) => {
    return new google.Marker({
      ...m,
      map,
      icon: getIcon(google, m.icon),
    })
  })
}

export function mapBoundsGmap(google: any, markers: IPoint[]) {
  if (!markers.length || !google) return []

  const bounds = new google.LatLngBounds()
  markers.forEach((m) => {
    bounds.extend(m)
  })

  return bounds
}

export function mapPolylinesGmap(google: any, map: Element, polylines: IMapPolyline[]) {
  if (!polylines.length || !google || !map) return []

  return polylines.map((p) => {
    return new google.Polyline({
      ...p,
      map,
    })
  })
}

export function mapReverseGeocodingGmap(results: IMapGeocoderResult[]): IMapGeocoderReverse[] {
  return results.map((r) => ({
    types: r.types,
    placeId: r.place_id,
    address: r.formatted_address,
    locationType: r.geometry.location_type,
    location: {
      lat: r.geometry.location.lat(),
      lng: r.geometry.location.lng(),
    },
  }))
}

export function reverseGeocoding(
  google: any,
  request: IMapGeocoderRequest
): Promise<{ isSuccess: boolean; data: IMapGeocoderReverse[] }> {
  return new Promise((res) => {
    const rs = { isSuccess: false, data: [] as IMapGeocoderReverse[] }

    if (typeof google !== 'object' || !google.maps) res(rs)

    const geocoder = new google.maps.Geocoder()

    geocoder.geocode(request, (results: IMapGeocoderResult[], status: MAP_GEOCODER_STATUS) => {
      if (status !== MAP_GEOCODER_STATUS.OK) res(rs)

      const geocodingData = mapReverseGeocodingGmap(results)

      rs.data = geocodingData
      rs.isSuccess = !!geocodingData.length

      res(rs)
    })
  })
}

export function formatLatLng(value: number, numberToFixed: number = 7): number {
  return typeof +value === 'number' && !Number.isNaN(+value)
    ? +value.toFixed(numberToFixed)
    : 0
}
