import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { FirebaseService } from '@/services'
import { IAuthState, IRootState } from '../utilities'

const state: IAuthState = {
  profile: {},
  isAuthenticated: false,
  isPermissionDenied: false,
  sessionID: ''
}

const mutations: MutationTree<IAuthState> = {
  SET_AUTH(state) {
    state.isAuthenticated = true
  },
  PURGE_AUTH(state) {
    state.profile = {}
    state.isAuthenticated = false
    state.isPermissionDenied = false
    state.sessionID = ''
  },
  SET_PERMISSION_DENIED(state, isDenied) {
    state.isPermissionDenied = isDenied
  },
  PRESET_USER_SESSION_ID(state, sessionID) {
    state.sessionID = sessionID
  },
  UPDATE_PROFILE(state, profile) {
    state.profile = profile
  },
}

const actions: ActionTree<IAuthState, IRootState> = {
  setUserAuth({ commit }) {
    commit('SET_AUTH')
  },
  updateUserProfile({ commit }, profile) {
    commit('UPDATE_PROFILE', profile)
  },
  logout({ commit, dispatch }) {
    commit('PURGE_AUTH')
    dispatch('ticketPurchased/setOrderIdResponse', '', { root: true })
    dispatch('database/purgeSaleMaster', null, { root: true })
    dispatch('database/setMainDataFetched', false, { root: true })
    dispatch('footerMenu/cleanFooterMenuState', null, { root: true })
    dispatch('gmapLocale/cleanGmapLocaleState', null, { root: true })
    FirebaseService.logout()
  },
  setPermissionDenied({ commit }, isDenied) {
    commit('SET_PERMISSION_DENIED', isDenied)
  },
  presetUserSessionID({ commit }, sessionID) {
    commit('PRESET_USER_SESSION_ID', sessionID)
  }
}

const getters: GetterTree<IAuthState, IRootState> = {
  profile: (state) => state.profile,
  isAuthenticated: (state) => state.isAuthenticated,
  isPermissionDenied: (state) => state.isPermissionDenied,
  userSessionID: (state) => state.sessionID
}

const authModule: Module<IAuthState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default authModule
