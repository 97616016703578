export const Regex = {
  CURRENCY_FORMAT: /(\d)(?=(\d{3})+(?!\d))/g,
}

export enum ProviderId {
  Password = 'password',
  Google = 'google.com',
}

export enum TicketStatus {
  Unused = 'UNUSE',
  Using = 'USING',
  Used = 'USED',
  Void = 'VOID',
}

export enum TicketMethod {
  SelfQr = 'SELF_QR',
}

export enum TicketType {
  TypeA = 'typeA',
  TypeB = 'typeB',
  TypeC = 'typeC',
}

export enum TicketCategory {
  Normal = 'NORMAL',
  CodeTicket = 'CODE_TICKET',
  DocomoBikeShare = 'DOCOMO_BIKESHARE',
}

export enum Mtp0110ScreenType {
  Username,
  Email,
  Password,
}

export enum ScreenId {
  MLO_0000 = 'MLO-0000',
  MLO_0200 = 'MLO-0200',
  MLO_0210 = 'MLO-0210',
  MLO_0900 = 'MLO-0900',
  MLC_0100 = 'MLC-0100',
  MLC_0110 = 'MLC-0110',
  MLC_0120 = 'MLC-0120',
  MLC_0211 = 'MLC_0211',
  MLC_0240 = 'MLC_0240',
  MLC_0320 = 'MLC-0320',
  MLC_0900 = 'MLC-0900',
  MTB_0100 = 'MTB-0100',
  MTB_0200 = 'MTB-0200',
  MTB_KA_0000 = 'MTB-KA-0000',
  MTL_0000 = 'MTL-0000',
  MTL_0101 = 'MTL-0101',
  MTL_0200 = 'MTL-0200',
  MTP_0000 = 'MTP-0000',
  MTP_0100 = 'MTP-0100',
  MTP_0110 = 'MTP-0110',
  MTP_0300 = 'MTP-0300',
  MGT_0000 = 'MGT-0000',
  MGT_0100 = 'MGT-0100',
  MRO_0011 = 'MRO-0011',
  MTL_KA_0000 = 'MTL-KA-0000',
  MLC_KA_0260 = 'MLC-KA-0260',
  MTL_KA_0100 = 'MTL-KA-0100',
}

export enum FooterMenuTab {
  Top = 'TopTab',
  QRCodeTab = 'QRCodeTab',
  MyTicket = 'MyTicketTab',
}

export enum ScreenProcess {
  // Top
  CancelPayment = 'cancel-payment',
  FinalPayment = 'final-payment',
  EditProfile = 'editting-profile',
  CheckProfile = 'looking-profile',
  PurchaseOptionTicket = 'purchase-option-process',
  ApplyTicket = 'apply-ticket-process',
  CreditCardManagement = 'MTB-0310',
  PurchaseRouteTicket = 'route-ticket-purchase-process',
  PurchaseOptionTicketFromTicketManagement = 'ticket-management-purchase-option-process',

  ExternalLowerLinks = 'external-lower-links',

  // QR Code Screen Process
  QRCodeProcess = 'qr-code-process',

  // My Ticket
  TicketManagementProcess = 'ticket-management-process',
  QrTicketSelectionProcess = 'qr-ticket-selection-process',
  GateQrTicketSelectionProcess = 'gate-qr-ticket-selection-process',
}

export enum FirebaseApiName {
  getRedirectResult = 'getRedirectResult',
  signInWithEmailAndPassword = 'signInWithEmailAndPassword',
  signInWithRedirect = 'signInWithRedirect',
  createUserWithEmailAndPassword = 'createUserWithEmailAndPassword',
  sendPasswordResetEmail = 'sendPasswordResetEmail',
  updateEmail = 'updateEmail',
  updatePassword = 'updatePassword',
  sendEmailVerification = 'sendEmailVerification',
}

export enum MAP_COLOR {
  WHITE = '#FFFFFF',
  RED = '#FF0000',
}

export const MAP_OPTIONS = {
  ZOOM: 17,
  CENTER: { lat: 35.652832, lng: 139.839478 },
  DEFAULT_UI: false,
  FULL_CONTROL: false,
  ZOOM_CONTROL: false,
  TYPE_CONTROL: false,
  VIEW_CONTROL: false,
  SCALE_CONTROL: false,
  ROTATE_CONTROL: false,
  CLICKABLE_ICON: false,
}

export enum MAP_MARKER_TYPE {
  SYMBOL = 'symbol',
  IMAGE = 'image',
}

export enum MAP_GEOCODER_LOCATION_TYPE {
  APPROXIMATE = 'APPROXIMATE',
  GEOMETRIC_CENTER = 'GEOMETRIC_CENTER',
  RANGE_INTERPOLATED = 'RANGE_INTERPOLATED',
  ROOFTOP = 'ROOFTOP',
}

export enum MAP_GEOCODER_STATUS {
  OK = 'OK',
  ERROR = 'ERROR',
  ZERO_RESULTS = 'ZERO_RESULTS',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  REQUEST_DENIED = 'REQUEST_DENIED',
  INVALID_REQUEST = 'INVALID_REQUEST',
  OVER_QUERY_LIMIT = 'OVER_QUERY_LIMIT',
}

export const ROUTE_SELECT_FIELD = {
  DEPARTURE: 0,
  ARRIVAL: 1,
  WAYPOINT_ONE: 2,
  WAYPOINT_TWO: 3,
}

export enum CandidateSiteTypeIcon {
  marker = 'mdiMapMarker',
}

export const CURRENT_LOCATION = '現在地'

export const TARGET_POINT_TYPE = {
  stationCode: 'stationCode',
  placeID: 'placeID',
  geoPoint: 'geoPoint',
}

export enum BATTERY_LEVEL {
  batterylow = 0,
  battery30 = 1,
  battery80 = 2,
  batteryfull = 3,
}

export enum REVERSE_STATUS {
  unreserved = 'UNRESERVED',
  reserved = 'RESERVED',
  using = 'USING',
}

export enum CSA_TYPE {
  passcode = 'PASSCODE',
  qrcode = 'QRCODE',
}

export enum TransportInfoKey {
  WALK = 'walk',
  PLANE = 'plane',
  BULLETTRAIN = 'bullettrain',
  LIMITEDEXPRESS = 'limitedexpress',
  CAR = 'car',
  BUS = 'bus',
  SHIP = 'ship',
  CYCLE = 'cycle',
}

export enum TransportType {
  walk = 'walk',
  plane = 'plane',
  bullettrain = 'bullettrain',
  limitedexpress = 'limitedexpress',
  car = 'car',
  bus = 'bus',
  ship = 'ship',
  cycle = 'cycle',
}
export enum MovingMethod {
  walk = 'walk',
  plane = 'plane',
  train = 'train',
  car = 'car',
  bus = 'bus',
  ship = 'ship',
  cycle = 'cycle',
  strange = 'strange',
}

export enum MovementIcon {
  walk = 'mdiWalk',
  plane = 'mdiAirplane',
  train = 'mdiTrain',
  car = 'mdiTaxi',
  bus = 'mdiBusStop',
  ship = 'mdiFerry',
  cycle = 'mdiBicycle',
  strange = 'mdiCar',
}

export enum DatetimePickerSearchType {
  departure = '出発',
  arrival = '到着',
  firstTrain = '始発',
  lastTrain = '終電',
}

export enum RouteResultSortType {
  time = '到着が早い',
  price = '料金が安い',
  transfer = '乗換が少ない',
}

export const DATE_TIME_PICKER_SEARCH_TYPE = {
  departure: 'departure',
  arrival: 'arrival',
  firstTrain: 'firstTrain',
  lastTrain: 'lastTrain',
}

export const ROUTE_RESULT_SORT_TYPE = {
  time: 'time',
  price: 'price',
  transfer: 'transfer',
}
export enum CreditCardValidateRule {
  SecurityCodeMaxLength = 4,
  CardNumberMaxLength = 16,
}

export const PAY_FREQUENCY_OPTIONS = [2, 3, 5, 6, 10, 12, 15, 18, 20, 24]

export const DATE_PICKER_DRUM_ROLL_MULTIPLE = 8
export const DATE_PICKER_DRUM_ROLL_PREV_DATE = 7

export enum FooterMenuEvent {
  ChangeTab = '[Footer Menu] change activated tab',
}

export enum LanguageEvent {
  LanguageChanged = '[Language Event] language changed',
}

export const INPUT_DEBOUNCE_TIME = 300

export enum SaleStatus {
  Unprocessed = 'UNPROCESSED',
  Fail = 'FAIL',
  Auth = 'AUTH',
  Sales = 'SALES',
  Void = 'VOID',
  NotSales = 'NOTSALES',
}

export enum SENT_MAIL_STATUS {
  PROCESSING = 'PROCESSING',
  FAIL = 'FAIL',
  SUCCESS = 'SUCCESS',
  UNPROCESSED = 'UNPROCESSED',
}

export enum CHECK_RESULT {
  EMAILDUPLICATION = 'EMAILDUPLICATION',
  IDDUPLICATION = 'IDDUPLICATION',
  OK = 'OK',
  REGISTERED = 'REGISTERED',
}

export enum ORDER_KIND {
  APPLY = 'APPLY',
  TRANSFER = 'TRANSFER',
  RECEIVE = 'RECEIVE',
}

export enum EXTERNAL_LINK_DISPLAY_TYPE {
  LINK = 'LINK',
  GROUP = 'GROUP',
}

export enum APPLY_STATUS {
  APPLYING = 'APPLYING',
  EXAMINED = 'EXAMINED',
  EXAMINING = 'EXAMINING',
}

export enum EXAMINATION_RESULT {
  Approval = 0,
  Rejected_Not_Qualified = 1,
  Rejected_issued = 2,
  Rejected_With_Delinquency = 3,
}
