import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import {
  ICompanyMaster,
  IGroupMaster,
  IOptionMaster,
  IPriceMaster,
  ITicketMaster,
} from '@/models'
import { IRootState, ITicketPaymentModuleState } from '../utilities'
import { IRouteTicketInfo } from '@/custommodels/IRouteSearchCustom'

function mapTicketWithPrice(ticket: ITicketMaster, prices: IPriceMaster[]) {
  // let priceKeys = Object.keys(ticket.priceInfo.prices)
  // return prices
  //   .filter((price) => priceKeys.includes(price.identifier))
  //   .sort((a, b) => a.sortKey.localeCompare(b.sortKey))
  //   .map((price) => {
  //     return {
  //       imageURL: price.iconURL,
  //       identifier: price.identifier,
  //       display: price.display,
  //       price: ticket.priceInfo.prices[price.identifier],
  //     }
  //   })
  return []
}

function mapRouteTicketPurchasedStatus(
  ticket: ITicketMaster,
  routeSelectedTicketIdList: { ticketID: string; hasPurchased: boolean }[]
) {
  const routeTicket = routeSelectedTicketIdList.find((item) => item.ticketID === ticket.id)
  return routeTicket ? routeTicket.hasPurchased : false
}

function mapTicketWithCompanies(ticket: ITicketMaster, companies: ICompanyMaster[]) {
  let companiesId = Object.keys(ticket.companys)
  return companies
    .filter((company) => companiesId.includes(company.id))
    .map((company) => ({ ...company }))
}

const state: ITicketPaymentModuleState = {
  currentTicketId: '',
  currentGroupId: '',
  currentGroupTicketIdList: [],
  isNewBuy: false,
  optionsSelectedId: [],
  quantityPerson: null,
  routeSelectedTicketIdList: [],
  currentQRCode: '',
}

const mutations: MutationTree<ITicketPaymentModuleState> = {
  SET_CURRENT_QR_CODE(state, newValue) {
    state.currentQRCode = newValue
  },
  SET_CURRENT_TICKET(state, infoBuy) {
    state.currentTicketId = infoBuy.ticketId
    state.isNewBuy = infoBuy.isNewBuy
  },
  SET_CURRENT_GROUP(state, data) {
    state.currentGroupId = data
  },
  SET_CURRENT_GROUP_TICKET_LIST(state, data) {
    state.currentGroupTicketIdList = data
  },
  SET_OPTIONS_TICKET(state, data) {
    state.optionsSelectedId = data
    state.quantityPerson = null
  },
  SET_QUANTITY(state, quantityPerson) {
    state.quantityPerson = quantityPerson
  },
  SET_ROUTE_TICKET_LIST(state, ticketList: IRouteTicketInfo[]) {
    state.routeSelectedTicketIdList = ticketList.map((ticket) => ({
      ticketID: ticket.ticketID,
      hasPurchased: ticket.purchased,
    }))
  },
}

const actions: ActionTree<ITicketPaymentModuleState, IRootState> = {
  setCurrentQRCode({ commit }, newQRCode: string) {
    commit('SET_CURRENT_QR_CODE', newQRCode)
  },
  setNewBuyTicket({ commit }, ticketId: string) {
    commit('SET_CURRENT_TICKET', { ticketId, isNewBuy: true })
    commit('SET_OPTIONS_TICKET', [])
  },
  setAddBuyTicket({ commit }, ticketId: string) {
    commit('SET_CURRENT_TICKET', { ticketId, isNewBuy: false })
    commit('SET_OPTIONS_TICKET', [])
  },
  setCurrentGroup({ commit }, groupId: string) {
    commit('SET_CURRENT_GROUP', groupId)
  },
  setCurrentGroupTicketIdList({ commit }, tickets: string[]) {
    commit('SET_CURRENT_GROUP_TICKET_LIST', tickets)
  },
  setOptionsTicket({ commit }, optionsId: string[]) {
    commit('SET_OPTIONS_TICKET', optionsId)
  },
  setQuantityPerson({ commit, state }, quantitys: object) {
    commit('SET_QUANTITY', quantitys)
  },
  setRouteSelectedTicketIdList({ commit }, ticketList: IRouteTicketInfo[]) {
    commit('SET_ROUTE_TICKET_LIST', ticketList)
  },
}

const getters: GetterTree<ITicketPaymentModuleState, IRootState> = {
  currentQRCode: (state) => state.currentQRCode,
  isNewBuy: (state): boolean => state.isNewBuy,
  currentTicket: (state, getter, { database }): any | null => {
    let ticket = database.ticketMaster.find((_ticket) => _ticket.id === state.currentTicketId)
    if (!ticket) {
      return null
    } else {
      // create companies for ticket/companies
      return {
        ...ticket,
        id: ticket.id,
        useEnd: ticket.useEnd || {},
        localCompanies: mapTicketWithCompanies(
          ticket,
          database.companyMaster.filter((company) => !company.isHide)
        ),
        localPrices: mapTicketWithPrice(ticket, database.priceMaster),
      }
    }
  },
  // currentGroup: (state, getters, { database }): IGroupMaster | null => {
  //   let group = database.groupMaster.find((_group) => _group.id === state.currentGroupId)
  //   if (!group) {
  //     return null
  //   } else {
  //     return {
  //       ...group,
  //       id: group.id,
  //       tickets: state.currentGroupTicketIdList,
  //       iconURL: group.iconURL || '',
  //       imageURL: group.imageURL || '',
  //     }
  //   }
  // },
  optionsSelected: (state, getter, { database }): ITicketMaster[] => {
    return database.ticketMaster
      .filter((ticket) => state.optionsSelectedId.includes(ticket.id))
      .map((ticket) => {
        return {
          ...ticket,
          id: ticket.id,
          localCompanies: mapTicketWithCompanies(ticket, database.companyMaster),
          localPrices: mapTicketWithPrice(ticket, database.priceMaster),
        }
      })
  },
  options: (state, getter, { database }): IOptionMaster[] => {
    if (state.currentTicketId) {
      return database.optionMaster
        .filter((option) => getter.currentTicket.options.includes(option.id) && option.canBuy)
        .map((option) => {
          return {
            ...option,
            id: option.id,
            localTickets: database.ticketMaster
            .filter(
              (ticket) => 
              option.tickets.includes(ticket.id)
              )
            .map((ticket) => {
                return {
                  ...ticket,
                  id: ticket.id,
                  localPrices: mapTicketWithPrice(ticket, database.priceMaster),
                }
              }),
          }
        })
    }
    return []
  },
  groupMasterByMap: (state, getters, { database }): any => {
    return database.groupMaster.reduce((obj: any, item) => {
      obj[item.id] = item;
      return obj;
    }, {})
  },
  categoriesMasterByMap: (state, getters, { database }): any => {
    return database.categoryMaster.reduce((obj: any, item) => {
      obj[item.id] = item;
      return obj;
    }, {})
  },
  ticketMasterByMap: (state, getters, { database }): any => {
    return database.ticketMaster.reduce((obj: any, item) => {
      obj[item.id] = item;
      return obj;
    }, {})
  },
  tickets: (state, getter, { database }): ITicketMaster[] => {
    // if (getter.currentGroup && getter.currentGroup.tickets) {
    //   const tickets: any[] = []

    //   // ・バックエンドの「販売中チケット一覧取得API 」より「チケットグループID」を取得する。
    //   // 　その後、テーブル仕様書「チケットグループ情報」で「チケットグループID」が紐づくデータのみ表示する
    //   getter.currentGroup.tickets.forEach((ticketId) => {
    //     const ticket = getter.ticketMasterByMap[ticketId]
    //     if (ticket) {
    //       tickets.push({
    //         ...ticket,
    //         id: ticket.id,
    //         localPrices: mapTicketWithPrice(ticket, database.priceMaster),
    //       })
    //     }
    //   })

    //   return tickets
    // } else {
    return []
    // }
  },
  quantityPerson: (state) => state.quantityPerson,
  routeSelectedTicketList: (state, getters, { database }): any[] => {
    return database.ticketMaster
    .filter(
      (ticket: ITicketMaster) =>
        state.routeSelectedTicketIdList.some(
          (routeTicket) => routeTicket.ticketID === ticket.id
        )
      )
      .map((ticket: ITicketMaster) => {
        // merge priceInfo/prices of ticket with priceMaster
        return {
          ...ticket,
          id: ticket.id,
          localPrices: mapTicketWithPrice(ticket, database.priceMaster),
          hasPurchased: mapRouteTicketPurchasedStatus(ticket, state.routeSelectedTicketIdList),
        }
      })
  },
}

const ticketModule: Module<ITicketPaymentModuleState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default ticketModule
