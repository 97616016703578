import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { IFooterMenuState, IRootState } from '../utilities'
import { FooterMenuTab, ScreenId, ScreenProcess } from '@/utilities'

const state: IFooterMenuState = {
  currentTab: FooterMenuTab.MyTicket,
  redirectToMLC_0320: false,
  currentFacilityId: '',
  isScanQRCode: false,
  redirectToMyTicketTab: false,
  redirectToMTL_0000: false,
  isSuccessToReceiveTicket: false,

  currentProcessOfTopTab: ScreenId.MTP_0000,
  currentProcessOfMyTicketTab: ScreenProcess.TicketManagementProcess,
  currentProcessOfQrCodeTab: ScreenProcess.QRCodeProcess,

  currentExternalParentLink: {},

  topTabDefaultProcessRenderKey: 1,
  myTicketTabDefaultProcessRenderKey: 1,
  qrCodeTabDefaultProcessRenderKey: 1,

  purchaseRouteTicketRenderKey: 1,
  purchaseOptionTicketFromTicketManagementRenderKey: 1
}

const mutations: MutationTree<IFooterMenuState> = {
  TOGGLE_SCAN_QR_CODE(state, newStatus) {
    state.isScanQRCode = newStatus
  },
  UPDATE_CURRENT_FACILITY_ID(state, newFacilityId) {
    state.currentFacilityId = newFacilityId
  },
  REDIRECT_TO_MLC_0320(state, newValue) {
    state.redirectToMLC_0320 = newValue
  },
  UPDATE_STATUS_OF_RECEIVE_TICKET(state, newValue) {
    state.isSuccessToReceiveTicket = newValue
  },
  REDIRECT_TO_MTL_0000(state, newValue) {
    state.redirectToMTL_0000 = newValue
  },
  CHANGE_CURRENT_TAB(state, newTab) {
    state.currentTab = newTab
  },

  CHANGE_CURRENT_PROCESS_OF_TOP_TAB(state, newProcess) {
    state.currentProcessOfTopTab = newProcess
  },
  CHANGE_CURRENT_PROCESS_OF_MY_TICKET_TAB(state, newProcess) {
    state.currentProcessOfMyTicketTab = newProcess
  },
  CHANGE_CURRENT_PROCESS_OF_QR_CODE_TAB(state, newProcess) {
    state.currentProcessOfQrCodeTab = newProcess
  },
  CHANGE_CURRENT_EXTERNAL_PARENT_LINK(state, name) {
    state.currentExternalParentLink = name
  },

  CHANGE_TOP_TAB_DEFAULT_PROCESS_RENDER_KEY(state) {
    state.topTabDefaultProcessRenderKey += 1
  },
  CHANGE_MY_TICKET_TAB_DEFAULT_PROCESS_RENDER_KEY(state) {
    state.myTicketTabDefaultProcessRenderKey += 1
  },
  CHANGE_QR_CODE_TAB_DEFAULT_PROCESS_RENDER_KEY(state) {
    state.qrCodeTabDefaultProcessRenderKey += 1
  },

  CHANGE_PURCHASE_ROUTE_TICKET_RENDER_KEY(state) {
    state.purchaseRouteTicketRenderKey += 1
  },
  CHANGE_PURCHASE_OPTION_FROM_TICKET_MANAGEMENT_RENDER_KEY(state) {
    state.purchaseOptionTicketFromTicketManagementRenderKey += 1
  },

  PURGE_STATE(state) {
    state.currentTab = FooterMenuTab.MyTicket

    state.currentProcessOfTopTab = ScreenId.MTP_0000
    state.currentProcessOfMyTicketTab = ScreenProcess.TicketManagementProcess
    state.currentProcessOfQrCodeTab = ScreenProcess.QRCodeProcess
    state.topTabDefaultProcessRenderKey = 1

    state.myTicketTabDefaultProcessRenderKey = 1
    state.qrCodeTabDefaultProcessRenderKey = 1

    state.purchaseRouteTicketRenderKey = 1
    state.purchaseOptionTicketFromTicketManagementRenderKey = 1
  },

  UPDATE_REDIRECT_TO_MY_TICKET_TAB(state, newValue) {
    state.redirectToMyTicketTab = newValue
  }
}

const actions: ActionTree<IFooterMenuState, IRootState> = {
  toggleScanQRCode({ commit }, newStatus) {
    commit('TOGGLE_SCAN_QR_CODE', newStatus)
  },
  updateCurrentFacilityID({ commit }, newFacilityId) {
    commit('UPDATE_CURRENT_FACILITY_ID', newFacilityId)
  },
  changeRedirectToMLC_0320({ commit }, newScreen) {
    commit('REDIRECT_TO_MLC_0320', newScreen)
  },
  updateStatusOfReceiveTicket({ commit }, newValue) {
    commit('UPDATE_STATUS_OF_RECEIVE_TICKET', newValue)
  },
  changeRedirectToMTL_0000({ commit }, newValue) {
    commit('REDIRECT_TO_MTL_0000', newValue)
  },
  switchToTopTabWithProcess({ commit }, newProcess) {
    commit('CHANGE_CURRENT_TAB', FooterMenuTab.Top)
    commit('CHANGE_CURRENT_PROCESS_OF_TOP_TAB', newProcess)
    scrollToTop()
  },
  switchToMyTicketTabWithProcess({ commit }, newProcess) {
    commit('CHANGE_CURRENT_TAB', FooterMenuTab.MyTicket)
    commit('CHANGE_CURRENT_PROCESS_OF_MY_TICKET_TAB', newProcess)
    scrollToTop()
  },
  switchToQRCodeTabWithProcess({ commit }, newProcess) {
    commit('CHANGE_CURRENT_TAB', FooterMenuTab.QRCodeTab)
    commit('CHANGE_CURRENT_PROCESS_OF_QR_CODE_TAB', newProcess)
    scrollToTop()
  },

  crossToTopTabWithProcess({ commit, state }, newProcess) {
    if (state.currentProcessOfTopTab !== newProcess) {
      commit('CHANGE_CURRENT_TAB', FooterMenuTab.Top)
      commit('CHANGE_CURRENT_PROCESS_OF_TOP_TAB', newProcess)
      return
    }

    if (newProcess === ScreenProcess.PurchaseRouteTicket) {
      commit('CHANGE_PURCHASE_ROUTE_TICKET_RENDER_KEY')
      commit('CHANGE_CURRENT_TAB', FooterMenuTab.Top)
      return
    }

    if (newProcess === ScreenProcess.PurchaseOptionTicketFromTicketManagement) {
      commit('CHANGE_PURCHASE_OPTION_FROM_TICKET_MANAGEMENT_RENDER_KEY')
      commit('CHANGE_CURRENT_TAB', FooterMenuTab.Top)
      return
    }
  },

  switchToTopTab({ commit }) {
    commit('CHANGE_CURRENT_TAB', FooterMenuTab.Top)
    scrollToTop()
  },
  switchToMyTicketTab({ commit }) {
    commit('CHANGE_CURRENT_TAB', FooterMenuTab.MyTicket)
    scrollToTop()
  },
  switchToRouteSearchTab({ commit }) {
    commit('CHANGE_CURRENT_TAB', FooterMenuTab.QRCodeTab)
    scrollToTop()
  },

  changeCurrentProcessOfTopTab({ commit }, newProcess) {
    commit('CHANGE_CURRENT_PROCESS_OF_TOP_TAB', newProcess)
  },
  changeCurrentProcessOfMyTicketTab({ commit }, newProcess) {
    commit('CHANGE_CURRENT_PROCESS_OF_MY_TICKET_TAB', newProcess)
  },
  changeCurrentProcessOfQRCodeTab({ commit }, newProcess) {
    commit('CHANGE_CURRENT_PROCESS_OF_QR_CODE_TAB', newProcess)
  },
  changeCurrentExternalParentLink({ commit }, name) {
    commit('CHANGE_CURRENT_EXTERNAL_PARENT_LINK', name)
  },

  restartTopTabToDefaultProcess({ commit }) {
    commit('CHANGE_TOP_TAB_DEFAULT_PROCESS_RENDER_KEY')
    commit('CHANGE_CURRENT_PROCESS_OF_TOP_TAB', ScreenId.MTP_0000)
    scrollToTop()
  },
  restartMyTicketTabToDefaultProcess({ commit }) {
    commit('CHANGE_MY_TICKET_TAB_DEFAULT_PROCESS_RENDER_KEY')
    commit('CHANGE_CURRENT_PROCESS_OF_MY_TICKET_TAB', ScreenProcess.TicketManagementProcess)
    scrollToTop()
  },
  restartQRCodeTabToDefaultProcess({ commit }) {
    commit('CHANGE_QR_CODE_TAB_DEFAULT_PROCESS_RENDER_KEY')
    commit('CHANGE_CURRENT_PROCESS_OF_QR_CODE_TAB', ScreenProcess.QRCodeProcess)
    scrollToTop()
  },

  cleanFooterMenuState({ commit }) {
    commit('PURGE_STATE')
  },

  updateRedirectToMyTicketTab({ commit }, newValue) {
    commit('UPDATE_REDIRECT_TO_MY_TICKET_TAB', newValue)
  },

  async handleChangeFooterTabProcessOnAPIError({ state, dispatch }) {
    switch (state.currentTab) {
      case FooterMenuTab.Top:
        await dispatch('restartTopTabToDefaultProcess')
        break;
      case FooterMenuTab.QRCodeTab:
        await dispatch('restartQRCodeTabToDefaultProcess')
        await dispatch('switchToTopTabWithProcess', ScreenId.MTP_0000)
        break;
      case FooterMenuTab.MyTicket:
        if (state.redirectToMyTicketTab) {
          await dispatch('updateRedirectToMyTicketTab', false)
          // Do nothing : No screen transition
        } else {
          await dispatch('restartMyTicketTabToDefaultProcess')
          await dispatch('switchToTopTabWithProcess', ScreenId.MTP_0000)
        }
        break;
      default:
    }
  }
}

const getters: GetterTree<IFooterMenuState, IRootState> = {
  isScanQRCode: (state) => state.isScanQRCode,
  currentFacilityId: (state) => state.currentFacilityId,
  redirectToMLC_0320: (state) => state.redirectToMLC_0320,
  isSuccessToReceiveTicket: (state) => state.isSuccessToReceiveTicket,
  redirectToMTL_0000: (state) => state.redirectToMTL_0000,
  currentTab: (state) => state.currentTab,

  currentProcessOfTopTab: (state) => state.currentProcessOfTopTab,
  currentProcessOfMyTicketTab: (state) => state.currentProcessOfMyTicketTab,
  currentProcessOfQrCodeTab: (state) => state.currentProcessOfQrCodeTab,
  currentExternalParentLink: (state) => state.currentExternalParentLink,

  topTabDefaultProcessRenderKey: (state) => state.topTabDefaultProcessRenderKey,
  myTicketTabDefaultProcessRenderKey: (state) => state.myTicketTabDefaultProcessRenderKey,
  qrCodeTabDefaultProcessRenderKey: (state) => state.qrCodeTabDefaultProcessRenderKey,

  purchaseRouteTicketRenderKey: (state) => state.purchaseRouteTicketRenderKey,
  purchaseOptionTicketFromTicketManagementRenderKey: (state) => state.purchaseOptionTicketFromTicketManagementRenderKey
}

const footerMenuModule: Module<IFooterMenuState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

function scrollToTop(): void {
  window.scroll(0, 0)
}

export default footerMenuModule
