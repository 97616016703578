import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { IOrderMaster } from '@/models'
import { flatten } from 'lodash'

@Component
export class FetchDataMixin extends Vue {
  @Action('database/getGroupMaster') private fetchGroupMaster!: () => Promise<any>
  @Action('database/getCategoryMaster') private fetchCategoryMaster!: () => Promise<any>
  @Action('database/getTicketMaster') private fetchTicketMaster!: () => Promise<any>
  @Action('database/getPriceMaster') private fetchPriceMaster!: () => Promise<any>
  @Action('database/getOptionMaster') private fetchOptionMaster!: () => Promise<any>
  @Action('database/getCompanyMaster') private fetchCompanyMaster!: () => Promise<any>
  @Action('database/getExternalLinkMaster') private fetchExternalLinkMaster!: () => Promise<
    any
  >
  @Action('database/getExternalLowerLinkMaster')
  private fetchExternalLowerLinkMaster!: () => Promise<any>
  @Action('database/getTicketsUnuse') private fetchTicketsUnused!: () => Promise<any>
  @Action('database/getTicketsUsing') private fetchTicketsUsing!: () => Promise<any>
  @Action('database/getTicketsUsed') private fetchTicketsUsed!: () => Promise<any>
  @Action('database/getSaleMaster') protected fetchSaleMaster!: () => Promise<any>
  @Action('database/getFacilityMaster') protected fetchFacilityMaster!: () => Promise<any>
  @Action('database/getOrders') protected fetchOrders!: () => Promise<any>
  @Action('database/getTicketsApplying') protected fetchTicketsApplying!: () => Promise<any>
  @Action('database/getTicketsExamining') protected fetchTicketsExamining!: () => Promise<any>
  @Action('database/getTicketsExamined') protected fetchTicketsExamined!: () => Promise<any>
  @Action('database/getUserApplyTickets') protected fetchUserApplyTickets!: () => Promise<any>
  @Getter('ticketPurchased/saleIdResponse') protected isRedirectFromPaymented!: boolean
  protected async fetchAppMainData(): Promise<any> {
    await Promise.all([
      this.fetchTicketMaster(),
      this.fetchCompanyMaster(),
      this.fetchTicketsUnused(),
      this.fetchTicketsUsing(),
      this.fetchTicketsUsed(),
      this.fetchExternalLinkMaster(),
      this.fetchExternalLowerLinkMaster(),
      this.fetchFacilityMaster(),
      this.fetchOrders(),
      this.fetchUserApplyTickets(),
    ])

    await this.getGateInfoForPurchasedTicket()
  }

  protected async getSaleAfterPaymented() {
    if (this.isRedirectFromPaymented) {
      await this.fetchSaleMaster()
    }
  }

  private async getGateInfoForPurchasedTicket() {
    const gateIDs = this.getGateIDFromPurchasedOrders()
    const promises = gateIDs.map((id) => this.$store.dispatch('database/getGateInfo', id))
    await Promise.all(promises)
  }

  private getGateIDFromPurchasedOrders() {
    const ticketsUnusedGateID: string[] = this.getGateIDFrom(
      this.$store.state.database.ticketsUnuse
    )
    const ticketsUsingGateID: string[] = this.getGateIDFrom(
      this.$store.state.database.ticketsUsing
    )
    const ticketsUsedGateID: string[] = this.getGateIDFrom(
      this.$store.state.database.ticketsUsed
    )
    const gateIDs = [...ticketsUnusedGateID, ...ticketsUsingGateID, ...ticketsUsedGateID]
    return this.extractDistinctValue(gateIDs)
  }

  private getGateIDFrom(orders: IOrderMaster[]): string[] {
    const checkGateInfoExist = (order: IOrderMaster) => order.gateInfo
    const ordersHasGateID = orders.filter(checkGateInfoExist)
    const gateIDs: string[][] = ordersHasGateID.map((order) =>
      order.gateInfo!.map((g) => g.gateID)
    )
    return flatten(gateIDs)
  }

  private extractDistinctValue(gateIDs: string[]) {
    return [...new Set(gateIDs)]
  }
}
