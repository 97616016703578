var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$store.getters['layout/showPrimaryHeader'])?_c('v-app-bar',{staticClass:"background-color-header pa-0",attrs:{"height":"60","elevate-on-scroll":"","app":""}},[_c('v-container',{staticStyle:{"padding-bottom":"8px"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"position":"relative"}},[_c('div',{style:({ width: _vm.$store.getters['layout/showBackButton'] ? 'max-content' : '0' })},[_c('p',{directives:[{name:"ripple",rawName:"v-ripple"},{name:"show",rawName:"v-show",value:(_vm.$store.getters['layout/showBackButton']),expression:"$store.getters['layout/showBackButton']"}],staticClass:"back-btn",staticStyle:{"width":"max-content"},on:{"click":_vm.handleBackButtonClick}},[_vm._v(_vm._s(_vm.$t('Header.002')))])]),_c('img',{staticClass:"d-block logo",style:({
            width: _vm.secondary.width,
            height: _vm.secondary.height,
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }),attrs:{"src":_vm.secondary.icon,"alt":""},on:{"click":_vm.handleAppNameClick}})])])],1):_vm._e(),(_vm.$store.getters['layout/showSecondaryHeader'])?[_c('v-container',{staticClass:"background-color-header"},[_c('div',{staticClass:"avatar"},[_c('img',{staticClass:"avatar__icon",style:({
            width: _vm.primary.width,
            height: _vm.primary.height,
          }),attrs:{"src":_vm.primary.icon,"alt":""}})])])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }