import {
  ICompanyMaster,
  IDocomoBikeShareInfoOrder,
  IDocomoBikeSharePortMaster,
  IOrderGateInfo,
  IOrderMaster,
  IPriceMaster,
  ITicketInfoStatus,
  ITicketInfo,
  ITicketInfoAmount,
  ITicketInfoCategory,
  ITicketInfoMethod,
  ITicketInfoUsage,
  ITicketMaster,
  IUserOrder,
  IUserTicket,
} from '@/models'
import { ITicketUsage } from '@/models/common'
import {
  CSA_TYPE,
  REVERSE_STATUS,
  TicketCategory,
  TicketMethod,
  TicketStatus,
  TicketType,
} from '@/utilities/constant'

function getCompanyIdsFor(ticketId: string, ticketMaster: any[]) {
  const ticketInfo = getTicketInfoFor(ticketId, ticketMaster)
  return ticketInfo ? Object.values(ticketInfo.companys) : []
}

function getTicketInfoFor(ticketId: string, ticketMaster: ITicketMaster[]) {
  return ticketMaster.find((ticket) => ticket.id === ticketId)
}

function getCompanyInfoFor(companyId: string, companyMaster: ICompanyMaster[]) {
  return companyMaster.find((company) => company.id === companyId)
}

function getPriceInfoFor(priceId: string, priceMaster: IPriceMaster[]) {
  return priceMaster.find((price) => price.id === priceId)
}

function createTicketDetail(price: IPriceMaster, order: IOrderMaster) {
  const result: any = {}
  result.iconUrl = price.iconURL
  result.iconName = price.display
  result.amount = order.numbers[price.id]
  return result
}

function createTicketStatus(status: TicketStatus): ITicketInfoStatus {
  return {
    value: status,
    isUnused: status === TicketStatus.Unused,
    isUsing: status === TicketStatus.Using,
    isUsed: status === TicketStatus.Used,
    isVoid: status === TicketStatus.Void,
  }
}

function createTicketMethod(method: TicketMethod): ITicketInfoMethod {
  return {
    value: method,
    isSelfQr: method === TicketMethod.SelfQr,
  }
}

function createTicketAmount(
  totalAmount: number,
  usages: ITicketUsage[] | null
): ITicketInfoAmount {
  const available = usages ? totalAmount - usages.length : totalAmount
  return {
    total: totalAmount,
    available,
    isShow: totalAmount >= 2,
  }
}

function createTicketUsage(usage: ITicketUsage): ITicketInfoUsage {
  return {
    date: usage.usageDateTime,
    method: createTicketMethod(usage.method),
    facilityID: usage.facilityID,
  }
}

function createTicketType(type: TicketType) {
  const result: any = {}
  result.value = type
  result.isTypeA = type === TicketType.TypeA
  result.isTypeB = type === TicketType.TypeB
  result.isTypeC = type === TicketType.TypeC
  return result
}

function createTickerGateInfo(gateInfo: IOrderGateInfo) {
  const result: IOrderGateInfo = {
    passDateTime: gateInfo.passDateTime,
    gateID: gateInfo.gateID,
  }
  return result
}

function createTicketCategory(category: TicketCategory): ITicketInfoCategory {
  const result: ITicketInfoCategory = {} as ITicketInfoCategory
  result.value = category
  result.isNormal = category === TicketCategory.Normal
  return result
}

function createDocomoBikeInfoOrder(bike: IDocomoBikeShareInfoOrder) {
  const result: any = {}
  result.csaType = createDocomoBikeCSAType(bike.csaType)
  result.portID = bike.portID
  result.portName = bike.portName
  result.passcode = bike.passcode
  result.supportID = bike.supportID
  result.cycleName = bike.cycleName
  result.phoneNumber = bike.phoneNumber
  result.identificationNumber = bike.cycleName
  result.reserveStatus = createDocomoBikeStatus(bike.reserveStatus)
  return result
}

function createDocomoBikeCSAType(type: CSA_TYPE) {
  const result: any = {}
  result.isPasscode = type === CSA_TYPE.passcode
  result.isQRcode = type === CSA_TYPE.qrcode
  return result
}

function createDocomoBikeStatus(reserveStatus: REVERSE_STATUS) {
  const result: any = {}
  result.isUnReserved = reserveStatus === REVERSE_STATUS.unreserved
  result.isReserved = reserveStatus === REVERSE_STATUS.reserved
  result.isUsing = reserveStatus === REVERSE_STATUS.using
  return result
}

export function createTicketData(
  ticket: IUserTicket,
  ticketMasterList: ITicketMaster[],
  companyMasterList: ICompanyMaster[]
) {
  const ticketMaster: ITicketMaster | undefined = getTicketInfoFor(
    ticket.ticketID,
    ticketMasterList
  )
  if (!ticketMaster) {
    return null
  }

  const companyIds: string[] = getCompanyIdsFor(ticket.ticketID, ticketMasterList)
  const companies: ICompanyMaster[] = companyIds
    .map((companyId) => getCompanyInfoFor(companyId, companyMasterList))
    .filter((company): company is ICompanyMaster => !!company)
    .sort((a, b) => a.sortKey.localeCompare(b.sortKey))

  return { ticket, ticketMaster, companies }
}

export function createTicket(ticketData: {
  ticket: IUserTicket
  ticketMaster: ITicketMaster
  companies: ICompanyMaster[]
}) {
  const result: ITicketInfo = {} as ITicketInfo
  result.id = ticketData.ticket.id
  result.ticketMasterId = ticketData.ticketMaster.id
  result.name = ticketData.ticketMaster.display.name
  result.companies = ticketData.companies
  result.imageUrl = ticketData.ticketMaster.display.imageURL
  result.description = ticketData.ticketMaster.display.description
  result.precautions = ticketData.ticketMaster.display.precautions
  result.canShowDetail = ticketData.ticket.canShowDetail
  result.canStartUsing = ticketData.ticket.canStartUsing
  result.isExpired = ticketData.ticket.isExpired
  result.howToUse = ticketData.ticketMaster.display.howToUse
  result.issueDate = ticketData.ticket.issueDateTime
  result.expireDate = ticketData.ticket.expireDateTime
  result.status = createTicketStatus(ticketData.ticket.status)
  result.method = createTicketMethod(ticketData.ticketMaster.useStart.method)
  result.category = createTicketCategory(ticketData.ticketMaster.category)
  result.amount = createTicketAmount(ticketData.ticket.counts, ticketData.ticket.usages)
  result.usages = ticketData.ticket.usages
    ? ticketData.ticket.usages.map((usage) => createTicketUsage(usage))
    : []
  return result
}

export function getDocomoMasterByTicketId(
  ticketId: string,
  docomoBikeSharePortMaster: IDocomoBikeSharePortMaster[]
) {
  return docomoBikeSharePortMaster.filter((d) => {
    const port = d.id === ticketId
    const isPortInfoHaveItems = !!d.portInfoList.length

    return port && isPortInfoHaveItems
  })
}
