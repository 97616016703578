import { RouteConfig } from 'vue-router'
import { ScreenId } from '@/utilities'

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'RootView',
    component: () => import('@/components/RootView.vue'),
  },
  // {
  //   path: '/ps/:id',
  //   name: ScreenId.MTB_0400,
  //   redirect: { name: 'RootView' },
  // }, removed
  {
    path: '/ts/:facilityId',
    name: ScreenId.MLC_0320,
    redirect: { name: 'RootView' },
  },
  // {
  //   path: '/gs/:gateId',
  //   name: ScreenId.MLC_0330,
  //   redirect: { name: 'RootView' },
  // }, removed
  {
    path: '/cd/:addQRTicketId',
    name: ScreenId.MTP_0000,
    redirect: { name: 'RootView' },
  },
  {
    path: '*',
    redirect: { name: 'RootView' },
  },
]

export default routes
