import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { firestoreAction } from 'vuexfire'
import { FirestoreService } from '@/services'
import { IDatabaseModuleState, IRootState } from '../utilities'
import { IOrderMaster, IUserApply, IUserData, IUserOrder, IUserTicket } from '@/models'
import { result } from 'lodash'

const state: IDatabaseModuleState = {
  administratorMaster: [],
  companyMaster: [],
  ticketMaster: [],
  ticketRegular: [],
  ticketCode: [],
  ticketDocomo: [],
  categoryMaster: [],
  groupMaster: [],
  priceMaster: [],
  optionMaster: [],
  ticketsUnuse: [],
  ticketsUsing: [],
  ticketsUsed: [],
  saleMaster: null,
  orders: [],
  packOrders: [],
  packOrderUsing: [],
  packOrderUsed: [],
  ticket: {} as IUserTicket,
  userData: { LatestSignedIn: {}, sessionID: '' } as IUserData,
  isMainDataFetched: false,
  docomoBikeSharePortMaster: [],
  gateMasterMap: {},
  order: {} as IUserOrder,
  facilityMaster: [],
  externalLinkMaster: [],
  externalLowerLinkMaster: [],
  ticketsApplying: [],
  ticketsExamining: [],
  ticketsExamined: [],
  userApplyTickets: [],
}

const mutations: MutationTree<IDatabaseModuleState> = {
  PURGE_SALE_MASTER: (state) => {
    state.saleMaster = null
  },
  MAIN_DATA_FETCHED: (state, isFetch: boolean) => {
    state.isMainDataFetched = isFetch
  },
  ADD_GATEINFO: (state, payload: any) => {
    state.gateMasterMap[payload.gateID] = payload.gateInfo
  },
}
const actions: ActionTree<IDatabaseModuleState, IRootState> = {
  getAdministratorMaster: firestoreAction(({ bindFirestoreRef }) => {
    return bindFirestoreRef('administratorMaster', FirestoreService.getAdministratorMaster(), {
      reset: true,
      wait: true,
    })
  }),
  getCompanyMaster: firestoreAction(({ bindFirestoreRef }) => {
    return bindFirestoreRef('companyMaster', FirestoreService.getCompanyMaster(), {
      reset: true,
      wait: true,
    })
  }),
  getCategoryMaster: firestoreAction(({ bindFirestoreRef }) => {
    return bindFirestoreRef('categoryMaster', FirestoreService.getCategoryMaster(), {
      reset: true,
      wait: true,
    })
  }),
  getTicketMaster: firestoreAction(({ bindFirestoreRef }) => {
    return bindFirestoreRef('ticketMaster', FirestoreService.getTicketMaster(), {
      reset: true,
      wait: true,
    })
  }),
  getPriceMaster: firestoreAction(({ bindFirestoreRef }) => {
    return bindFirestoreRef('priceMaster', FirestoreService.getPriceMaster(), {
      reset: true,
      wait: true,
    })
  }),
  getOptionMaster: firestoreAction(({ bindFirestoreRef }) => {
    return bindFirestoreRef('optionMaster', FirestoreService.getOptionMaster(), {
      reset: true,
      wait: true,
    })
  }),
  getExternalLinkMaster: firestoreAction(({ bindFirestoreRef }) => {
    return bindFirestoreRef('externalLinkMaster', FirestoreService.getExternalLinkMaster(), {
      reset: true,
      wait: true,
    })
  }),
  getExternalLowerLinkMaster: firestoreAction(({ bindFirestoreRef }) => {
    return bindFirestoreRef(
      'externalLowerLinkMaster',
      FirestoreService.getExternalLowerLinkMaster(),
      {
        reset: true,
        wait: true,
      }
    )
  }),
  getTicketsUnuse: firestoreAction(({ bindFirestoreRef, rootState }) => {
    return bindFirestoreRef(
      'ticketsUnuse',
      FirestoreService.getTicketsUnuse(rootState.auth.profile.uid),
      {
        reset: true,
        wait: true,
      }
    )
  }),
  getTicketsUsing: firestoreAction(({ bindFirestoreRef, rootState }) => {
    return bindFirestoreRef(
      'ticketsUsing',
      FirestoreService.getTicketsUsing(rootState.auth.profile.uid),
      {
        reset: true,
        wait: true,
      }
    )
  }),
  getTicketsUsed: firestoreAction(({ bindFirestoreRef, rootState }) => {
    return bindFirestoreRef(
      'ticketsUsed',
      FirestoreService.getTicketsUsed(
        rootState.auth.profile.uid,
        rootState.system.properties.mobile.displayHistoryLimit
      ),
      {
        reset: true,
        wait: true,
      }
    )
  }),
  getOrders: firestoreAction(({ bindFirestoreRef, rootState }) => {
    return bindFirestoreRef('orders', FirestoreService.getOrders(rootState.auth.profile.uid))
  }),
  getOrder: firestoreAction(({ bindFirestoreRef, rootState }, { orderID }) => {
    return bindFirestoreRef(
      'order',
      FirestoreService.getOrder(rootState.auth.profile.uid, orderID)
    )
  }),
  getSaleMaster: firestoreAction(({ bindFirestoreRef, rootState }) => {
    return bindFirestoreRef(
      'saleMaster',
      FirestoreService.getUserTransactions(rootState.ticketPurchased.saleIdResponse),
      {
        reset: true,
        wait: true,
      }
    )
  }),
  getPackOrder: firestoreAction(({ bindFirestoreRef, rootState }) => {
    return bindFirestoreRef(
      'packOrders',
      FirestoreService.getPackOrdersUnuse(rootState.auth.profile.uid),
      {
        reset: true,
        wait: true,
      }
    )
  }),
  getPackOrderUsing: firestoreAction(({ bindFirestoreRef, rootState }) => {
    return bindFirestoreRef(
      'packOrderUsing',
      FirestoreService.getPackOrdersUsing(rootState.auth.profile.uid),
      {
        reset: true,
        wait: true,
      }
    )
  }),
  getPackOrderUsed: firestoreAction(({ bindFirestoreRef, rootState }) => {
    return bindFirestoreRef(
      'packOrderUsed',
      FirestoreService.getPackOrdersUsed(rootState.auth.profile.uid),
      {
        reset: true,
        wait: true,
      }
    )
  }),
  getTicketFor: firestoreAction(({ bindFirestoreRef, rootState }, { ticketID }) => {
    return bindFirestoreRef(
      'ticket',
      FirestoreService.getTicketFor(rootState.auth.profile.uid, ticketID),
      {
        reset: true,
        wait: true,
      }
    )
  }),
  getUserData: firestoreAction(({ bindFirestoreRef, rootState }) => {
    return bindFirestoreRef(
      'userData',
      FirestoreService.getUserData(rootState.auth.profile.uid),
      {
        reset: true,
        wait: true,
      }
    )
  }),
  getDocomoBikeSharePortMaster: firestoreAction(({ bindFirestoreRef }) => {
    return bindFirestoreRef(
      'docomoBikeSharePortMaster',
      FirestoreService.getDocomoBikeSharePortMaster(),
      {
        reset: true,
        wait: true,
      }
    )
  }),
  purgeSaleMaster: ({ commit }) => {
    commit('PURGE_SALE_MASTER')
  },
  setMainDataFetched({ commit }, isFetch: boolean) {
    commit('MAIN_DATA_FETCHED', isFetch)
  },
  async getGateInfo(context, gateID) {
    // ゲート情報を読み込み済みであればstoreから取得する
    if (context.state.gateMasterMap[gateID] != null) {
      return context.state.gateMasterMap[gateID]
    }
    // 未読み込みであれば、gateIDを指定してゲート情報を取得する
    const gateInfo = await FirestoreService.getGateInfo(gateID)
    // 取得したゲート情報はストアに格納する
    context.commit('ADD_GATEINFO', { gateID, gateInfo })
    return gateInfo
  },
  getFacilityMaster: firestoreAction(({ bindFirestoreRef }) => {
    return bindFirestoreRef('facilityMaster', FirestoreService.getFacilityMaster(), {
      reset: true,
      wait: true,
    })
  }),
  getUserApplyTickets: firestoreAction(({ bindFirestoreRef, rootState }) => {
    return bindFirestoreRef(
      'userApplyTickets',
      FirestoreService.getUserApply(rootState.auth.profile.uid),
      {
        reset: true,
        wait: true,
      }
    )
  }),
  getTicketsApplying: firestoreAction(({ bindFirestoreRef }) => {
    return bindFirestoreRef(
      'ticketsApplying',
      FirestoreService.getApplyTicketStatusAPPLYING(),
      { reset: true, wait: true }
    )
  }),
  getTicketsExamining: firestoreAction(({ bindFirestoreRef }) => {
    return bindFirestoreRef(
      'ticketsExamining',
      FirestoreService.getApplyTicketStatusEXAMINING(),
      { reset: true, wait: true }
    )
  }),
  getTicketsExamined: firestoreAction(({ bindFirestoreRef }) => {
    return bindFirestoreRef(
      'ticketsExamined',
      FirestoreService.getApplyTicketStatusEXAMINED(),
      { reset: true, wait: true }
    )
  }),
}

const getters: GetterTree<IDatabaseModuleState, IRootState> = {
  isMainDataFetched: (state) => state.isMainDataFetched,
  docomoBikeSharePort: (state) => state.docomoBikeSharePortMaster,
}

const databaseModule: Module<IDatabaseModuleState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default databaseModule
