import Vue from 'vue'
import {
  formatCurrency,
  formatDateTime,
  formatDateTimeWithDayOfWeek,
  getContentByCurrentLanguage,
  getLocationByPriority,
  MovementIcon,
  MovingMethod,
  getInfoDepartureByPriority,
  getInfoArrivalByPriority,
  CURRENT_LOCATION,
} from '@/utilities'
import firebase from 'firebase'
import ICompanyMaster from '@/models/CompanyMaster'
import i18n, { LanguageCode } from '@/i18n'
import { ILocationInformation } from '@/custommodels'

Vue.filter('dateTime', (value: Date | number) => {
  try {
    return formatDateTime(value)
  } catch {
    return ''
  }
})

Vue.filter('dateTimeWithDayOfWeek', (value: Date | number) => {
  try {
    return formatDateTimeWithDayOfWeek(value)
  } catch {
    return ''
  }
})

Vue.filter('currency', (value: string | number) => {
  try {
    if (!isNaN(+value)) {
      return formatCurrency(value)
    }
    return ''
  } catch {
    return ''
  }
})

Vue.filter('timestamp', (value: firebase.firestore.Timestamp) => {
  if (!value) return ''
  try {
    return formatDateTime(value.toDate())
  } catch {
    return ''
  }
})

Vue.filter('companyName', (companies: ICompanyMaster[]) => {
  try {
    return companies
      .filter((company) => !company.isHide)
      .reduce((acc, company) => {
        const companyName = getContentByCurrentLanguage(company.name)
        if (i18n.locale === LanguageCode.Ja) {
          return acc ? acc + '/' + companyName : companyName
        }

        return acc ? acc + '／' + companyName : companyName
      }, '')
  } catch (error) {
    return ''
  }
})

Vue.filter('getContentByCurrentLanguage', (content: any) => {
  return getContentByCurrentLanguage(content)
})

Vue.filter('locationDisplay', (location: ILocationInformation) => {
  const locationByPriority = getLocationByPriority(location)
  return locationByPriority === CURRENT_LOCATION
    ? i18n.t('MRO-0000.003').toString()
    : locationByPriority
})

Vue.filter('locationDepartureDisplay', (location: ILocationInformation) => {
  return getInfoDepartureByPriority(location)
})

Vue.filter('getInfoArrivalByPriority', (location: ILocationInformation) => {
  return getInfoArrivalByPriority(location)
})

Vue.filter('movementIcon', (movingMethod: MovingMethod) => {
  return Vue.prototype.$icon[MovementIcon[movingMethod]]
})

Vue.filter('maskCardNumber', (cardNumber: string) => {
  if (cardNumber.includes('*')) {
    return cardNumber
  }

  try {
    const cardNumberLength = cardNumber.length
    if (cardNumberLength <= 4) {
      return cardNumber
    }

    const last4Digit = cardNumber.substring(cardNumberLength - 4, cardNumberLength)
    if (cardNumberLength <= 10) {
      const masked = cardNumber
        .slice(0, cardNumberLength - 4)
        .split('')
        .map((v) => '*')
        .join('')
      return `${masked}${last4Digit}`
    }

    const restNo = cardNumber.slice(0, cardNumberLength - 10)
    const masked = cardNumber
      .slice(cardNumberLength - 10, cardNumberLength - 4)
      .split('')
      .map((v) => '*')
      .join('')
    return `${restNo}${masked}${last4Digit}`
  } catch {
    return cardNumber
  }
})
