import _Vue from 'vue'
import {
	mdiAirplane,
	mdiBattery,
	mdiBattery30,
	mdiBattery80,
	mdiBatteryOutline,
	mdiBicycle,
	mdiBusStop,
	mdiCar,
	mdiCircle,
	mdiCircleDouble,
	mdiCircleMedium,
	mdiClockOutline,
	mdiClose,
	mdiCrosshairsGps,
	mdiCurrencyJpy,
	mdiFerry,
	mdiHome,
	mdiMapMarker,
	mdiMapMarkerRadius,
	mdiMinus,
	mdiPlay,
	mdiPlus,
	mdiPlusCircleOutline,
	mdiQrcode,
	mdiSwapVertical,
	mdiTaxi,
	mdiTicketAccount,
	mdiTrain,
	mdiTrainCar,
	mdiTransitConnectionVariant,
	mdiTriangle,
	mdiWalk,
} from '@mdi/js'

const Icon = {
	eventBus: new _Vue(),
	install: (Vue: typeof _Vue, options?: any) => {
		Vue.prototype.$icon = {
			mdiHome,
			mdiTriangle,
			mdiPlus,
			mdiPlusCircleOutline,
			mdiMinus,
			mdiMapMarker,
			mdiMapMarkerRadius,
			mdiWalk,
			mdiTrainCar,
			mdiTicketAccount,
			mdiCrosshairsGps,
			mdiClose,
			mdiCircle,
			mdiCircleMedium,
			mdiCircleDouble,
			mdiPlay,
			mdiClockOutline,
			mdiCurrencyJpy,
			mdiTransitConnectionVariant,
			mdiBatteryOutline,
			mdiBattery30,
			mdiBattery80,
			mdiBattery,
			mdiSwapVertical,
			mdiAirplane,
			mdiTrain,
			mdiTaxi,
			mdiBusStop,
			mdiFerry,
			mdiBicycle,
			mdiCar,
			mdiQrcode
		}
	},
}

interface IIconPlugin {
	mdiHome: string
	mdiTriangle: string
	mdiPlus: string
	mdiPlusCircleOutline: string
	mdiMinus: string
	mdiMapMarker,
	mdiMapMarkerRadius: string
	mdiWalk: string
	mdiTrainCar: string
	mdiTicketAccount: string
	mdiCrosshairsGps: string
	mdiClose: string
	mdiCircle: string
	mdiCircleMedium: string
	mdiCircleDouble: string
	mdiPlay: string
	mdiClockOutline: string
	mdiCurrencyJpy: string
	mdiTransitConnectionVariant: string
	mdiBatteryOutline: string
	mdiBattery30: string
	mdiBattery80: string
	mdiBattery: string
	mdiSwapVertical: string
	mdiAirplane: string
	mdiTrain: string
	mdiTaxi: string
	mdiBusStop: string
	mdiFerry: string
	mdiBicycle: string
	mdiCar: string,
	mdiQrcode: string
}

declare module 'vue/types/vue' {
	interface Vue {
		$icon: IIconPlugin
	}
}

export default Icon
