import Vue from 'vue'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import store from '@/store'
import { D11, ScreenId } from '@/utilities'
import { globalEventBus } from '@/main'

const config: AxiosRequestConfig = {
  headers: {
    // Access-Control-Allow-Origin get from db
    'Content-Type': 'application/json',
  },
}

export const httpClient = axios.create(config)

export const httpError = handleHttpError

httpClient.interceptors.response.use(
  (response) => {
    // Do something with response data
    return handleResponseData(response)
  },
  (error) => {
    // Do something with response error
    return handleResponseError(error)
  }
)

function handleResponseData(response: AxiosResponse) {
  const { errorCode } = response.data
  if (!errorCode || errorCode.length === 0) {
    return response
  } else {
    Vue.prototype.$dialog.showApiError(
      ScreenId.MLC_0110,
      errorCode,
      handleApiErrorDialogOkClick
    )
    return Promise.resolve(response)
  }
}

function handleResponseError(error: AxiosError) {
  const errorCode = 'ERROR'
  Vue.prototype.$dialog.showApiError(ScreenId.MLC_0110, errorCode, handleApiErrorDialogOkClick)
  return Promise.reject(error)
}

function handleHttpError(error: any) {
  Vue.prototype.$dialog.showError(ScreenId.MLC_0100, D11.ERROR, handleApiErrorDialogOkClick)
  return Promise.reject(new Error(''))
}

function handleApiErrorDialogOkClick() {
  if (store.getters['auth/isAuthenticated']) {
    store.dispatch('footerMenu/handleChangeFooterTabProcessOnAPIError')
  } else {
    globalEventBus.$emit('app-name-click')
  }
}
